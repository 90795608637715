import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAdjustList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/banner-adjust/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setAdjust(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/banner-adjust/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadAdjustListExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_ASYNC_BACKEND_SERVER}/manage/banner-adjust/download/adm/`,
            params,
            { responseType: 'blob' },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadAdjustListExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_ASYNC_BACKEND_SERVER}/manage/banner-adjust/upload/adm/`,
            params,
            { responseType: 'blob' },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
