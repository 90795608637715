<template>
  <b-row>
    <b-col md="4">
      <b-form-group label="날짜">
        <v-select
          :value="selectedDate.label"
          label="label"
          :options="selectDateList"
          @input="updateDateFilter"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { computed } from '@vue/composition-api'

import {
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  props: {
    yearFilter: {
      type: Number,
      required: true,
    },
    monthFilter: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    moment.addRealMonth = function addRealMonth(d) {
      const fm = moment(d).add(1, 'M')
      const fmEnd = moment(fm).endOf('month')
      return d.date() !== fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm
    }
    const startDate = moment('2022-10-01')
    const endDate = moment()
    const duration = moment.duration(endDate.diff(startDate))

    const selectDateList = []
    let updateDate = startDate
    for (let i = 0; i <= duration.months() + 12 * duration.years(); i += 1) {
      selectDateList.push({
        label: updateDate.format('YYYY-MM'),
        value: updateDate,
      })
      updateDate = moment.addRealMonth(updateDate)
    }

    const selectedDate = computed(() => selectDateList.filter(item => item.label === `${props.yearFilter}-${String(props.monthFilter).padStart(2, '0')}`)[0])

    const updateDateFilter = value => {
      emit('update:yearFilter', value.value.year())
      emit('update:monthFilter', value.value.month() + 1)
    }

    return {
      selectDateList,
      selectedDate,

      updateDateFilter,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
