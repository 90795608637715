<template>
  <div>
    <tagby-filter
      :categoryOptions="categoryOptions"
      :initial-search-params="initialSearchParams"
      @search="search"
    >
      <template #body>
        <date-selector
          :yearFilter="year"
          :monthFilter="month"
          @update:yearFilter="val => year = val"
          @update:monthFilter="val => month = val"
        />
      </template>

      <template #buttons>
        <b-button
          variant="outline-primary"
          class="mr-1"
          @click="downloadAdjustListExcel"
        >
          <span class="align-middle">구글 정산 페이지 이동</span>
        </b-button>
        <b-button
          variant="outline-primary"
          class="mr-1"
          @click="(isOpenAdjustModal = true)"
        >
          <span class="align-middle">선택 정산하기</span>
        </b-button>
        <b-button
          variant="outline-success"
          class="mr-1"
          :disabled="isDownloadingExcelAdjust"
          @click="downloadAdjustListExcel"
        >
          <b-spinner
            v-if="isDownloadingExcelAdjust"
            small
          />
          <span class="align-middle">정산 데이터 엑셀 다운로드</span>
        </b-button>
        <b-button
          variant="outline-success"
          class="mr-1"
          :disabled="isAdjusting"
          @click="(isOpenAdjustUploadModal = true)"
        >
          <b-spinner
            v-if="isAdjusting"
            small
          />
          <span class="align-middle">정산 데이터 엑셀 업로드</span>
        </b-button>
      </template>
    </tagby-filter>
    <!-- <statistics-section
      :totalAdjustEarnings="totalAdjustEarnings"
      :totalEstimatedEarnings="totalEstimatedEarnings"
    /> -->
    <tagby-list
      ref="table"
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      selectable
      @page-change="changePage"
      @row-selected="selectItems"
    >
      <template #cell(inf_idx)="data">
        <b-link
          :to="{ name: 'member-inf-detail', params: { idx: data.item.inf_idx } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.inf_idx }}
        </b-link>
      </template>

      <template #cell(estimated_earnings)="data">
        <div class="d-flex align-items-center">
          <b-form-input
            id="estimated_earnings"
            v-model="data.item.estimated_earnings"
            style="width: 150px"
            size="sm"
            autocomplete="off"
            :disabled="data.item.is_adjust"
            type="number"
            trim
            @input="(val) => data.item.estimated_earnings = val"
          />
          <span style="margin-left: 5px">P</span>
        </div>
      </template>

      <template #cell(adjust_earnings)="data">
        <span>{{ data.item.adjust_earnings }} P</span>
      </template>

      <template #cell(set_adjust)="data">
        <b-button
          size="sm"
          variant="primary"
          class="mr-1 d-flex"
          :disabled="(data.item.is_adjust || isAdjusting)"
          @click="selectedAdjustItems = [data.item]; isOpenAdjustModal = true;"
        >
          <b-spinner
            v-if="isAdjusting"
            small
          />
          <span class="mr-25 align-middle">정산하기</span>
        </b-button>
      </template>
    </tagby-list>

    <adjust-modal
      :visible="isOpenAdjustModal"
      :year="year"
      :month="month"
      :data="selectedAdjustItems"
      @ok="setAdjust(selectedAdjustItems)"
      @cancel="selectedAdjustItems = []; isOpenAdjustModal = false;"
      @hidden="isOpenAdjustModal = false;"
    />

    <adjust-upload-modal
      :visible="isOpenAdjustUploadModal"
      :year="year"
      :month="month"
      :data="selectedAdjustItems"
      @ok="uploadAdjustExcel"
      @cancel="isOpenAdjustUploadModal = false;"
      @hidden="isOpenAdjustUploadModal = false;"
    />
  </div>
</template>

<script>
import {
  BLink,
  BButton,
  BSpinner,
  BFormInput,
} from 'bootstrap-vue'
import useBannerAdjust from './useBannerAdjust'
import DateSelector from './components/DateSelector.vue'
import AdjustModal from './components/AdjustModal.vue'
import AdjustUploadModal from './components/AdjustUploadModal.vue'
// import StatisticsSection from './components/Statistics.vue'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'

export default {
  components: {
    BLink,
    BFormInput,
    BButton,
    BSpinner,
    DateSelector,
    AdjustModal,
    AdjustUploadModal,
    // StatisticsSection,
    TagbyFilter,
    TagbyList,
  },
  setup() {
    return {
      ...useBannerAdjust(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
width: 90px;
}
</style>
