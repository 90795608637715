<template>
  <BModal
    :visible="visible"
    ok-title="확인"
    cancel-title="취소"
    centered
    :title="`[${year}-${String(month).padStart(2, '0')}] 광고 수익 정산`"
    @ok="$emit('ok')"
    @hidden="$emit('hidden')"
  >
    <div
      v-for="(_data, idx) in data"
      :key="idx"
    >
      [{{ _data.type }}] {{ _data.url }} : {{ _data.estimated_earnings }} P
    </div>
  </BModal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    year: { type: Number, required: true },
    month: { type: Number, required: true },
    visible: { type: Boolean, default: false },
    data: { type: Array },
  },
}
</script>
