<template>
  <BModal
    :visible="visible"
    ok-title="확인"
    cancel-title="취소"
    centered
    :title="`[${year}-${String(month).padStart(2, '0')}] 광고 수익 대량 정산`"
    @ok="$emit('ok', file)"
    @hidden="$emit('hidden')"
  >
    <BFormFile v-model="file" />
  </BModal>
</template>

<script>
import {
  BModal,
  BFormFile,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

export default {
  components: {
    BModal,
    BFormFile,
  },
  props: {
    year: { type: Number, required: true },
    month: { type: Number, required: true },
    visible: { type: Boolean, default: false },
  },
  setup() {
    const file = ref(null)
    return {
      file,
    }
  },
}
</script>
