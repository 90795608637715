import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'

import BannerAdjustStoreModule from './storeModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-banner-adjust-list'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })
  const initialSearchParams = ref({})
  const table = ref()
  const today = moment()
  const year = ref(today.year())
  const month = ref(today.month())
  const currentPage = ref(parseInt(router.history.current.query.page, 10) || 1)
  const isOpenAdjustModal = ref(false)
  const isOpenAdjustUploadModal = ref(false)
  const selectedAdjustItems = ref([])

  const searchParams = computed(() => {
    const routerQuery = router.history.current.query
    return {
      page: currentPage.value,
      size: parseInt(routerQuery.size, 10) || 20,
      total: parseInt(routerQuery.total, 10) || 0,
      year: year.value,
      month: month.value,
      ...initialSearchParams.value,
    }
  })

  const syncronizeParamsWithUrl = () => {
    const routerQuery = router.history.current.query
    initialSearchParams.value = Object
      .keys(routerQuery)
      .reduce((previoutValue, currentValue) => {
        if (['page', 'size', 'total', 'year', 'month'].includes(currentValue)) {
          return previoutValue
        }
        if (currentValue === null || currentValue === '') {
          return previoutValue
        }
        if (typeof routerQuery[currentValue] === 'string') {
          return {
            ...previoutValue,
            [currentValue]: [routerQuery[currentValue]],
          }
        }
        return {
          ...previoutValue,
          [currentValue]: routerQuery[currentValue],
        }
      },
      {})
  }

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => {})
  }

  const categoryOptions = [
    {
      label: '링크 타입',
      value: 'type',
      paramName: 'type',
      format: 'checkbox',
      options: [
        { text: '태그바이 링크', value: 'LINK_MAIN' },
        { text: '스토어', value: 'STORE_MAIN' },
      ],
    },
    {
      label: '링크 PATH',
      value: 'URL_PATH',
      paramName: 'url_path',
    },
    {
      label: 'INF_NAME',
      value: 'INF_NAME',
      paramName: 'inf_name',
    },
  ]

  const tableColumns = [
    { key: 'inf_idx', label: 'inf_idx' },
    { key: 'type', label: '링크 타입' },
    { key: 'url', label: '링크 path' },
    { key: 'estimated_earnings', label: '예상 수익' },
    { key: 'adjust_earnings', label: '정산 금액' },
    { key: 'set_adjust', label: '정산하기' },
  ]
  const items = ref([])
//   const totalEstimatedEarnings = computed(() => items.value.reduce(item => item.estimated_earnings, 0))
//   const totalAdjustEarnings = computed(() => items.value.filter(item => item.is_adjust).reduce(item => item.adjust_earnings, 0))
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/getAdjustList`, searchParams.value)
      .then(response => {
        const { data } = response
        items.value = data.data
        totalRows.value = data.total_count
        searchParams.value.total = data.total_count
      })
      .catch(() => {
        makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const search = params => {
    initialSearchParams.value = {
      ...initialSearchParams.value,
      ...params,
    }
    currentPage.value = 1
    router.replace({
      query: { ...searchParams.value, page: currentPage.value },
    }).catch(() => {})
    fetchItems()
  }

  const setAdjust = setData => {
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/setAdjust`, {
        year: year.value,
        month: month.value,
        data: setData.map(item => { return { ...item, amount: item.estimated_earnings } }),
      })
      .then(() => {
        makeToast('primary', '정산에 성공 했습니다')
      })
      .catch(() => {
        makeToast('danger', '정산에 실패 했습니다')
      })
      .finally(() => {
        selectedAdjustItems.value = []
        table.value.table.refUserListTable.clearSelected()
        fetchItems()
      })
  }

  const isAdjusting = ref(false)
  const uploadAdjust = setData => {
    isAdjusting.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/setAdjust`, {
        year: year.value,
        month: month.value,
        data: setData.map(item => { return { ...item, amount: item.estimated_earnings } }),
      })
      .then(() => {
        makeToast('primary', '정산에 성공 했습니다')
      })
      .catch(() => {
        makeToast('danger', '정산에 실패 했습니다')
      })
      .finally(() => {
        isAdjusting.value = false
        selectedAdjustItems.value = []
        fetchItems()
      })
  }

  const selectItems = _items => {
    console.log(table.value.table.refUserListTable)
    _items.forEach((element, idx) => {
      console.log(element, idx)
    })
    selectedAdjustItems.value = _items.filter(_item => !_item.is_adjust)
  }

  const openAdjustModal = _items => {
    selectedAdjustItems.value = _items.filter(_item => !_item.is_adjust)
  }

  const changePage = params => {
    currentPage.value = params.page
    router.replace({
      query: { ...searchParams.value, ...params },
    }).catch(() => {})
    fetchItems()
  }

  const downloadFile = (url, name) => {
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    if (name) {
      tempLink.setAttribute('download', name)
    } else {
      tempLink.setAttribute('download', '')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  const isDownloadingExcelAdjust = ref(false)
  const downloadAdjustListExcel = () => {
    isDownloadingExcelAdjust.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/downloadAdjustListExcel`, searchParams.value)
      .then(response => {
        const fileName = `banner_adjust_${year.value}-${month.value}_${moment().format('YYYYMMDDHHmmss')}.xlsx`
        const file = new File([response.data], fileName, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(file)
        downloadFile(url, fileName)
      })
      .catch(() => {
        makeToast('danger', '엑셀 변환에 실패 했습니다')
      })
      .finally(() => {
        isDownloadingExcelAdjust.value = false
        fetchItems()
      })
  }

  const uploadAdjustExcel = _file => {
    const formData = new FormData()
    formData.append('year', year.value)
    formData.append('month', month.value)
    formData.append('file', _file)

    isAdjusting.value = true

    store
      .dispatch(`${APP_STORE_MODULE_NAME}/uploadAdjustListExcel`, formData)
      .then(() => {
        makeToast('primary', '대량 등록에 성공 했습니다')
      })
      .catch(() => {
        makeToast('danger', '대량 등록에 실패 했습니다')
      })
      .finally(() => {
        isAdjusting.value = false
        fetchItems()
      })
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, BannerAdjustStoreModule)
    syncronizeParamsWithUrl()
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  return {
    table,

    year,
    month,
    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    // totalEstimatedEarnings,
    // totalAdjustEarnings,
    totalRows,
    perPage,
    currentPage,

    isOpenAdjustModal,
    isOpenAdjustUploadModal,
    selectedAdjustItems,
    isBusy,

    isDownloadingExcelAdjust,
    isAdjusting,

    fetchItems,
    search,
    changePage,
    selectItems,
    openAdjustModal,

    setAdjust,
    uploadAdjust,
    downloadAdjustListExcel,
    uploadAdjustExcel,
  }
}
